import React, { useState } from "react"
import { graphql, Link } from "gatsby";
import groupBy from 'lodash/groupBy';
import styled from "@emotion/styled";
import { keyframes }  from "@emotion/react";
import burgerBot from "../images/burgerBot.png" 
import { Strip } from '../components/Strip';
import SiteHeader  from '../components/SiteHeader';

// styles
const pageStyles = {
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}

const MakeBurgersWrapper = styled.div`
  display: grid;
  grid-template: 'img' 'button';
  align-items: self-start;

  img {
    grid-area: img;
    justify-self: center;
  }

  button {
    align-self: self-start;
    justify-self: self-start;
  }

  h2 {
    align-self: self-end;
  }

  @media only screen and (min-width: 992px) {
    grid-template: 'img title' 'img button';
  }

`;

const BurgerBotTitle = styled.h2`
  font-family: 'Press Start 2P', cursive;
  display: none;

  @media only screen and (min-width: 992px) {
    display: block;
  }
`

const RandomBurgerButton = styled.button`
  font-family: 'Press Start 2P', cursive;
  background: yellow;
  border: none;
  font-size: 2rem;
  padding: 1rem;
  cursor: pointer;
  text-align: left;
  border: solid 0.25rem black;

  @media only screen and (min-width: 992px) {
    transform: rotate(-5deg) translateY(-16px);
    transition: transform 0.25s;
    &:hover {
      transform: scale(1.4) rotate(-10deg) translateY(-16px);
    }
  }

  span {
    font-size: 1.5rem;
  }
`

const SeeBurgerWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const SeeBurgerLink = styled.a`
  font-family: 'Press Start 2P', cursive;
  background: yellow;
  color: black;
  border: 0.125rem solid black;
  text-decoration: none;
  font-size: 1.5rem;
  padding: 1rem;
  transform: rotate(3deg);
  &:hover {
    box-shadow: 0.25rem 0.25rem 2px white;
    transform: rotate(-3deg);
  }
  transition: transform 0.25s;

`

const bounce = keyframes`
  from, 20%, 53%, 80%, to {
    transform: translateX(0);
  }

  40%, 43% {
    transform: translateX(15px);
  }

  70% {
    transform: translateX(8px);
  }

  90% {
    transform: translateX(2px);
  }
`

const SeeBurgerLinkArrow = styled.div`
display: inline-block;
  animation: ${bounce} 1s ease infinite;
  ${({ isBouncing }) => isBouncing ? '' : 'animation: none;' }
  margin: 0 0.5rem;
`;

const SpinnerNameControlsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr auto auto auto;
`

const SpinnerName = styled.div`
  font-family: 'Fredoka One', cursive;
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
`

const SpinnerWrapper = styled.div`
  display: grid;
  grid-gap: 1rem;
`;

const SpinnerWheelWrapper = styled.div`
  height: 12rem;
  overflow: hidden;
  border: solid 1px;
  position: relative;
  box-shadow: 0 0 0.5rem 0.5rem hotpink;
  background: yellow;
  border: 0.125rem solid black;
`

const SpinnerWheel = styled.div`
  overflow: hidden;
  position: absolute;
  transform: translateY(${({selected = 0}) =>  selected * -12.125}rem);
  transition: transform 0.5s;
  right: 0;
  left: 0;
  color: black;
`

const SpinnerItem = styled.div`
  font-family: 'Fredoka One', cursive;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 12rem;
  overflow: hidden;
  font-size: 2rem;
  border-bottom: 0.125rem solid black;
`

const Spinner = ({ name, ingredients, selected, onClick }) => {
  return (
    <SpinnerWrapper>
      <SpinnerNameControlsWrapper>
        <SpinnerName>{name}</SpinnerName>
        <button disabled={selected === 0} onClick={() => onClick(selected - 1)}>❮</button>
        <button onClick={() => onClick(Math.round(Math.random() * (ingredients.length - 1)))}>Spin</button>
        <button disabled={selected === ingredients.length - 1} onClick={() => onClick(selected + 1)}>❯</button>
      </SpinnerNameControlsWrapper>
      <SpinnerWheelWrapper>
        <SpinnerWheel selected={selected}>
          {ingredients.map(({title}) => ((<SpinnerItem key={title}>{title}</SpinnerItem>)))}
        </SpinnerWheel>
      </SpinnerWheelWrapper>
    </SpinnerWrapper>
  )
}

const SpinnersWrapper = styled.div`
  display: grid;
  grid-gap: 2rem;
  @media only screen and (min-width: 992px) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 3rem;
  }
`;

const LightsName = styled.div`
  font-family: 'Fredoka One', cursive;
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
`
const LightsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2rem;
  @media only screen and (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
  @media only screen and (min-width: 992px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`;

const Light = styled.button`
  font-family: 'Fredoka One', cursive;
  border: none;
  justify-items: self-start;
  align-items: center;
  display: grid;
  grid-template-columns: 1fr auto;
  padding: 1rem;
  font-size: 1.5rem;
  background: ${({ isSelected }) => isSelected ? 'yellow' : 'white' };
  transition: background 0.5s;
  cursor: pointer;
  box-shadow: 0 0 0.125rem 0.125rem black;

  &:after {
    display: block;
    line-height: 1rem;
    color: white;
    height: 1rem;
    width: 1rem;
    border: 2px solid hotpink;
    border-radius: 2px;
    transition: background 0.5s;
  }

  ${({ isSelected }) => isSelected ? `
    &:after {
      background: hotpink;
      content: '✓';
    }
  ` : `
    &:after {
      background: white;
      content: '';
    }
  ` };
`;

const extrasIsSelected = (selected,  index) => {
  const bitWiseNum = Math.pow(2, index);
  return selected & bitWiseNum;
}

const LightsContainer = styled.div`
  & + & {
    margin-top: 1.5rem;
  }
  hr {
    border-color: black;
  }
`

const Lights = ({ name, ingredients, selected, onClick }) => {
  return (
    <LightsContainer>
      <LightsName>{name}</LightsName>
      <hr />
      <LightsWrapper>
        {ingredients.map(({ title }, index) => {
          return (
            <Light onClick={() => onClick(index)} key={title} isSelected={extrasIsSelected(selected, index)}>{title}</Light>
          );
        })}
      </LightsWrapper>
    </LightsContainer>
  )
}

const BurgerMakerLayout = styled.div`
  display: grid;
  // grid-gap: 0.25rem;
`;


const HeaderLink = styled(Link)`
  font-family: 'Fredoka One', cursive;
  background: white;
  padding: 0.5rem;
  text-decoration: none;
  color: black;
  transform: rotate(5deg);
  transition: transform 0.5s;

  &:hover,
  &:focus {
    background: yellow;
    transform: rotate(-5deg);
  }
`;

// markup
const IndexPage = ({ data }) => {
  const [hasChanges, setHasChanges] = useState(false);
  const [selected, setSelected] = useState({
    construction:0,
    filling: 0,
    spices: 0,
    extras: 0,
    salad: 0,
    sauces: 0
  });
  const ingredients = groupBy(data.allMarkdownRemark.edges.map(({node}) => node.frontmatter), 'type');

  const constructionSlug = ingredients.construction[selected?.construction]?.slug;
  const fillingSlug = ingredients.filling[selected?.filling]?.slug;
  const spicesSlug = ingredients.spices[selected?.spices]?.slug;
  const extraSearch = ingredients.extras.filter((_, index) => {return extrasIsSelected(selected?.extras, index)}).map(({slug}) => slug).join('|');
  const saucesSearch = ingredients.sauces.filter((_, index) => {return extrasIsSelected(selected?.sauces, index)}).map(({slug}) => slug).join('|');
  const saladsSearch = ingredients.salad.filter((_, index) => {return extrasIsSelected(selected?.salad, index)}).map(({slug}) => slug).join('|');
  const selectedURL = `${constructionSlug}/${fillingSlug}/${spicesSlug}${extraSearch || saucesSearch || saladsSearch ? `?extras=${extraSearch}&sauces=${saucesSearch}&salads=${saladsSearch}` : ''}`;

  return (
    <main>
      <title>BurgerBot.net</title>
      <BurgerMakerLayout>
      <SiteHeader />
      <Strip variant="pink">
        <MakeBurgersWrapper>
          <img src={burgerBot} alt="BurgerBot.net" />
          {/* <p>Warning - burger bot isn't always great at making ideas...</p> */}
          <BurgerBotTitle>Burger Bot</BurgerBotTitle>
          <RandomBurgerButton
            onClick={() => {
              setSelected({
                construction: Math.round(Math.random() * (ingredients.construction.length - 1)),
                filling: Math.round(Math.random() * (ingredients.filling.length - 1)),
                spices: Math.round(Math.random() * (ingredients.spices.length - 1)),
                extras: Math.round(Math.random() * Math.pow(2, ingredients.extras.length) - 1),
                salad: Math.round(Math.random() * Math.pow(2, ingredients.salad.length) - 1),
                sauces: Math.round(Math.random() * Math.pow(2, ingredients.sauces.length) - 1)
              });
              setHasChanges(true);
            }}
          >
            <span>Give me a</span><br />Random Burger Idea
          </RandomBurgerButton>
        </MakeBurgersWrapper>
      </Strip>
      <Strip variant="black-strips">
        <SpinnersWrapper>
          <Spinner name="Construction" ingredients={ingredients.construction} selected={selected?.construction} onClick={(index) => {
            setSelected({...selected, construction: index });
            setHasChanges(true);
          }} />
          <Spinner name="Filling" ingredients={ingredients.filling} selected={selected?.filling} onClick={(index) => {
            setSelected({...selected, filling: index });
            setHasChanges(true);
          }} />
          <Spinner name="Spices" ingredients={ingredients.spices} selected={selected?.spices} onClick={(index) => {
            setSelected({...selected, spices: index });
            setHasChanges(true);
          }} />
        </SpinnersWrapper>
      </Strip>
      <Strip variant="pink-strips">
        <Lights name="Extras" ingredients={ingredients.extras} selected={selected?.extras} onClick={(index) => {
          setSelected({...selected, extras: selected.extras ^ Math.pow(2, index)  });
          setHasChanges(true);
        }} />
        <Lights name="Salad" ingredients={ingredients.salad} selected={selected?.salad} onClick={(index) => {
          setSelected({...selected, salad: selected.salad ^ Math.pow(2, index)  });
          setHasChanges(true);
        }} />
        <Lights name="Sauces" ingredients={ingredients.sauces} selected={selected?.sauces} onClick={(index) => {
          setSelected({...selected, sauces: selected.sauces ^ Math.pow(2, index)  });
          setHasChanges(true);
        }} />

      </Strip>
      <Strip variant="black">
        <SeeBurgerWrapper>
          <SeeBurgerLink href={selectedURL}>See this burger <SeeBurgerLinkArrow isBouncing={hasChanges}>➜</SeeBurgerLinkArrow></SeeBurgerLink>
        </SeeBurgerWrapper>
      </Strip>
      </BurgerMakerLayout>
    </main>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query MyQuery {
    allMarkdownRemark(sort: {fields: frontmatter___title}, filter: {frontmatter: {published: {ne: false}}}) {
      edges {
        node {
          frontmatter {
            title
            slug
            type
          }
        }
      }
    }
  }
`
